import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Table, Card, Container, Row, Col } from "react-bootstrap";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
          <p>{`Take a look to find out more about the project, news and upcoming COVID-19 related events from their partners.`}</p>
          <Container className="full-width" mdxType="Container">
            <Row mdxType="Row">
              <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/e4o2z7s4v5">
          <Card.Img variant="top" src="/final-newsletter.png" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/e4o2z7s4v5" className="pb-3">Autumn 2024 final newsletter</Card.Link>
      </Card>
    </Col>
              <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/r9e8g4t9b4">
          <Card.Img variant="top" src="/BY-COVID-newsletter-summer-2024.png" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/r9e8g4t9b4" className="pb-3">Summer 2024 newsletter</Card.Link>
      </Card>
    </Col>
              <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/b7t7y2m0q2">
          <Card.Img variant="top" src="/BY-COVID-newsletter-spring-2024.png" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/b7t7y2m0q2" className="pb-3">Spring 2024 newsletter</Card.Link>
      </Card>
    </Col>
            </Row>
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/j8k1v3j3k9">
          <Card.Img variant="top" src="/BY-COVID-newsletter-winter-2023.png" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/j8k1v3j3k9" className="pb-3">Winter 2023 newsletter</Card.Link>
      </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/b9b9k2s4g3">
          <Card.Img variant="top" src="/BY-COVID-newsletter-autumn-2023.jpeg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/b9b9k2s4g3" className="pb-3">Autumn 2023 newsletter</Card.Link>
      </Card>
    </Col>
   <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/a0i1x0y1h8">
          <Card.Img variant="top" src="/BY-Covid-newsletter-summer-2023.png" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/a0i1x0y1h8" className="pb-3">Summer 2023 newsletter</Card.Link>
      </Card>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/b4x4j2o3n1">
          <Card.Img variant="top" src="/BY-Covid-newsletter-spring-2023.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/b4x4j2o3n1" className="pb-3">Spring 2023 newsletter</Card.Link>
      </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/h6o2j4a1q6">
          <Card.Img variant="top" src="/BY-COVID-newsletter-4.jpeg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/h6o2j4a1q6" className="pb-3">Winter 2022 newsletter</Card.Link>
      </Card>
    </Col>
  <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID-autumn-2022-new.pdf">
          <Card.Img variant="top" src="/BY-COVID-newsletter-3.jpeg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID-autumn-2022-new.pdf" className="pb-3">Autumn 2022 newsletter</Card.Link>
      </Card>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_newsletter_Spring_2022.pdf">
          <Card.Img variant="top" src="/BY-COVID-newsletter-2.jpeg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_newsletter_Spring_2022.pdf" className="pb-3">Spring 2022 newsletter</Card.Link>
      </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="https://preview.mailerlite.com/i4u9r3i0h3">
          <Card.Img variant="top" src="/BY-first-newsletter.jpeg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="https://preview.mailerlite.com/i4u9r3i0h3" className="pb-3">February 2022 newsletter</Card.Link>
      </Card>
    </Col>
  </Row>
          </Container>
          <p>{`Don't forget you can stay in touch by `}<a href="https://www.subscribepage.com/bycovid_eu" target="_blank"><strong>{`subscribing to our newsletter`}</strong></a>{`.`}</p>
   </Col>
 </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      